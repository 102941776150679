@use "../../styles/global" as *;
@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.about {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  justify-content: space-between;
  @include desktop {
    flex-direction: row;
  }
  &__title {
    text-align: center;
    font-size: 2rem;
  }
  &__partners {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include desktop {
      flex-direction: row;
    }
    &--img {
      max-width: 635px;
      width: 100%;
    }
    &--link {
      margin: 1rem 1rem;
      max-width: 635px;
    }
  }
  &__container {
    max-width: 100%;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;

    @include desktop {
      margin-right: 6rem;
    }
    &--text {
      font-size: 1.5rem;
      letter-spacing: 1.5px;
      @include tablet {
        font-size: 2rem;
      }
    }
    &--text-paragraph {
      font-size: 1.25rem;
    }

    &--img-link {
      transition: color 0.3s ease;
      text-decoration: underline;
      font-size: 0.75rem;
      color: rgb(217, 217, 217);
    }

    &--img {
      width: 100%;
      // max-width: 600px;

      @include desktop {
        width: 40vw;
        padding: 0 4rem 4rem 8;
      }
    }
  }
}
